export const Prayers = {
  Asr: "العصر",
  Dhuhr: "الظهر",
  Fajr: "الفجر",
  Firstthird: "الثلث الأول",
  Imsak: "الإمساك",
  Isha: "العشاء",
  Lastthird: "الثلث الأخير",
  Maghrib: "المغرب",
  Midnight: "منتصف الليل",
  Sunrise: "شروق الشمس",
  Sunset: "غروب الشمس",
};
