export const categories = [
  {
    id: 2,
    name: "سياسة",
    slug: "سياسة",
    isMenu: true,
    description: "أخبار وتحليلات حول الأحداث السياسية المحلية والدولية",
    created_at: "2024-09-20T10:15:30Z",
    updated_at: "2024-09-21T12:45:10Z",
  },
  {
    id: 3,
    name: "اقتصاد",
    slug: "اقتصاد",
    isMenu: true,
    description: "تغطية لأحدث الأخبار الاقتصادية المحلية والعالمية",
    created_at: "2024-09-20T10:15:30Z",
    updated_at: "2024-09-21T12:45:10Z",
  },

  {
    id: 4,
    name: "رياضة",
    slug: "رياضة",
    isMenu: true,
    description: "أحدث الأخبار والمستجدات الرياضية",
    created_at: "2024-09-20T10:15:30Z",
    updated_at: "2024-09-21T12:45:10Z",
  },
  {
    id: 5,
    name: "فن",
    slug: "فن",
    isMenu: true,
    description: "أخبار الفن",
    created_at: "2024-09-20T10:15:30Z",
    updated_at: "2024-09-21T12:45:10Z",
  },
  {
    id: 6,
    name: "دولي",
    slug: "دولي",
    isMenu: true,
    description: "أخبار الدولي",
    created_at: "2024-09-20T10:15:30Z",
    updated_at: "2024-09-21T12:45:10Z",
  },
  {
    id: 7,
    name: "منوعات",
    slug: "منوعات",
    description: "أخبار المنوعات",
    created_at: "2024-09-20T10:15:30Z",
    updated_at: "2024-09-21T12:45:10Z",
  },
  {
    id: 8,
    name: "مجتمع",
    slug: "مجتمع",
    description: "تقارير وتحليلات اجتماعية وثقافية",
    created_at: "2024-09-20T10:15:30Z",
    updated_at: "2024-09-21T12:45:10Z",
  },
];
